<template>
  <!-- 电力轮式巡检机器人，英文版 -->
  <div class="product electricWheel_en" v-if="elmentList.length">
    <div class="banner_video" :id="'elm' + elmentList[0].id">
      <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[0].phoneImg" v-else />
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div>
          <el-button
            size="small"
            style="margin-right: 20px"
            class="btn-div-experience btn-div-order"
            plain
            @click="retention"
            >Order Now</el-button
          >
          <el-button
            size="small"
            class="btn-div-experience btn-div-test"
            plain
            @click="retention"
            >Register for Demo</el-button
          >
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            The products appearing in the video on this page are for reference only. The appearance, configuration, model, and other details of the products may vary. Please refer to the actual local delivery for details.
          </div>
        </div>
      </div>
    </div>
    <div class="electricWheel-content" >
      <div class="content-one" :id="'elm' + elmentList[1].id">
        <img :src="elmentList[1].pcImg" v-if="this.$store.state.pageset.pcshow" />
        <img :src="elmentList[1].phoneImg" v-else />
        <div class="dec" v-animate="'queue-bottom'">
          <div class="dec-left">
            <p class="p1">{{ elmentList[1].descList[0].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right">
            <p class="p2">
              {{ elmentList[1].descList[1].descName }}
            </p>
          </div>
          <div class="phone-order" v-if="!this.$store.state.pageset.pcshow">
            <el-button
              size="small"
              class="btn-div-experience white-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
        </div>
      </div>
      <div class="content-two" :id="'elm' + elmentList[2].id">
        <img :src="elmentList[2].pcImg" v-if="this.$store.state.pageset.pcshow" />
        <img :src="elmentList[2].phoneImg" v-else />
        <div class="dec">
          <div class="dec-left">
            <p class="p1">{{ elmentList[2].descList[0].descName }}</p>
            <p class="p2">{{ elmentList[2].descList[1].descName }}</p>
            <el-button
              v-if="this.$store.state.pageset.pcshow"
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Order Now</el-button
            >
          </div>
          <div class="dec-right"></div>
          <el-button
            v-if="!this.$store.state.pageset.pcshow"
            size="small"
            class="btn-div-experience black-btn"
            plain
            @click="retention"
            >Order Now</el-button
          >
        </div>
      </div>
      <!-- 左右排列参数 -->
      <div class="content-tree" v-if="this.$store.state.pageset.pcshow">
        <div v-for="index in 6" :key="index" :id="'elm' + elmentList[index + 2].id">
          <div class="fun-item" v-if="index % 2 === 0">
            <div class="fun-left">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
            <div class="fun-right">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">{{ elmentList[index + 2].descList[0].descName }}</p>
                <p class="p2">{{ elmentList[index + 2].descList[1].descName }}</p>
              </div>
            </div>
          </div>
          <div class="fun-item" v-else>
            <div class="fun-left">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">{{ elmentList[index + 2].descList[0].descName }}</p>
                <p class="p2">{{ elmentList[index + 2].descList[1].descName }}</p>
              </div>
            </div>
            <div class="fun-right">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-else>
        <div v-for="index in 6" :key="index">
          <div>
            <img :src="elmentList[index + 2].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 2].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 2].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- 产品参数 -->
      <div class="content-par-remark" :id="'elm' + elmentList[9].id">
        <div class="img">
          <img
            :src="elmentList[9].pcImg"
            v-if="$store.state.pageset.pcshow"
            style="width: 620px; height: 620px"
          />
          <img :src="elmentList[9].phoneImg" v-else />
        </div>
        <div class="dec">
          <p class="p1">{{ elmentList[9].descList[0].descName }}</p>
          <p class="p1">{{ elmentList[9].descList[1].descName }}</p>
          <div class="dec-con" v-animate="'queue-bottom'">
            <div class="dec-con-left">
              <ul class="des-ui">
                <li v-for="index in 6" :key="index">
                  <p>{{ elmentList[9].descList[index + 1].descName }}</p>
                  <p>{{ elmentList[9].descList[index + 13].descName }}</p>
                </li>
              </ul>
            </div>
            <div class="dec-con-right">
              <ul class="des-ui">
                <li v-for="index in 6" :key="index">
                  <p>{{ elmentList[9].descList[index + 7].descName }}</p>
                  <p>{{ elmentList[9].descList[index + 19].descName }}</p>
                </li>
              </ul>
            </div>
          </div>
          <el-button
            v-animate="'queue-bottom'"
            size="small"
            class="btn-div-experience white-btn"
            plain
            @click="openPdf"
            >Product Manual</el-button
          >
        </div>
      </div>

      <div class="content-par-order" :id="'elm' + elmentList[10].id">
        <div class="img" v-if="!this.$store.state.pageset.pcshow">
          <img :src="elmentList[10].phoneImg" style="width: 327px; height: 327px" />
        </div>
        <div class="dec" v-animate="'queue-bottom'">
          <p>{{ elmentList[10].descList[0].descName }}</p>
          <p>{{ elmentList[10].descList[1].descName }}</p>
          <div>
            <el-button
              size="small"
              style="background-color: black; color: #fff"
              class="btn-div-experience"
              plain
              @click="retention"
              >Order Now</el-button
            >
            <el-button
              size="small"
              class="btn-div-experience black-btn"
              plain
              @click="retention"
              >Register for Demo</el-button
            >
          </div>
        </div>
        <div class="img" v-if="this.$store.state.pageset.pcshow">
          <img :src="elmentList[10].pcImg" style="width: 650px; height: 650px" />
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import electricWheel_en from "./electricWheel_en.js";

export default electricWheel_en;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../electricWheel.scss";
@import "./electricWheel_en.scss";
@import "../../common.scss";
</style>
