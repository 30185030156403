
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId:92,
            //             phoneElementId: 103,
            //             descList: [
            //                 {
            //                     descName: 'Wheeled Inspection Robot for Power Industry',
            //                     sort: 1
            //                 }, {
            //                     descName: 'IP55',
            //                     sort: 2
            //                 }, {
            //                     descName: '0.8m/s',
            //                     sort: 3
            //                 }, {
            //                     descName: '≥8h',
            //                     sort: 4
            //                 }, {
            //                     descName: '≤40kg',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Protection Level',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Walking Speed',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Endurance Time',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Curb Weight',
            //                     sort: 9
            //                 }],
            //             type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 93,
            //             phoneElementId: 104,
            //             descList: [
            //                 {
            //                     descName: 'Wheeled Inspection Robot for Power Industry',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It can be used in application scenarios such as substations and converter stations. The shell is anti-static and anti-electromagnetic coated, and technologies such as trackless navigation, intelligent image identification and analysis, and multi-sensor combination are comprehensively used to replace manual equipment inspection work. Inspection is carried out for equipment appearance defects, meter readings, switch positions, equipment temperature measurement, sound acquisition, etc., effectively improving inspection efficiency and quality.',
            //                     sort: 2
            //                 },],
            //             type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId:94,
            //             phoneElementId: 105,
            //             descList: [
            //                 {
            //                     descName: 'Application Scenarios',
            //                     sort: 1
            //                 }, {
            //                     descName: 'It can be used in substations, converter stations and other intelligent inspection scenarios',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 95,
            //             phoneElementId: 106,
            //             descList: [
            //                 {
            //                     descName: 'Unmanned Autonomous Inspection',
            //                     sort: 1
            //                 }, {
            //                     descName: 'No manual intervention is required, when it conducts inspection, it finishes autonomous mapping with the equipped lidar, then it can achieve unmanned autonomous inspection according to the setted route when it carries out inspection',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 96,
            //             phoneElementId: 107,
            //             descList: [
            //                 {
            //                     descName: 'Intelligent Obstacle Avoidance Function',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with obstacle avoidance sensors, safe operation, obstacle avoidance and early warning are achieved through liar and anti-collision sensors.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 97,
            //             phoneElementId: 108,
            //             descList: [
            //                 {
            //                     descName: 'Infrared Temperature Measurement of Equipment',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with infrared thermal imager, it can collect the surface temperature of the device and give early warning for abnormal temperature.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 98,
            //             phoneElementId: 109,
            //             descList: [
            //                 {
            //                     descName: 'Meter Recognition Reading',
            //                     sort: 1
            //                 }, {
            //                     descName: 'By using AI visual technology to read data from various instruments and meters, if the collected data exceeds the threshold, an alert will be generated.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 7
            //         },
            //         {
            //             pcElementId: 99,
            //             phoneElementId: 110,
            //             descList: [
            //                 {
            //                     descName: 'Video Storage Monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Equipped with high-definition camera and video management equipment, it has live broadcasting and video recording functions, and can be centrally stored and replayed.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 8
            //         },
            //         {
            //             pcElementId: 100,
            //             phoneElementId: 111,
            //             descList: [
            //                 {
            //                     descName: 'Partial Discharge Monitoring',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Partial discharge monitoring for electrical equipment can effectively evaluate the degree of insulation deterioration inside the equipment.',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 9
            //         },
            //         {
            //             pcElementId: 101,
            //             phoneElementId: 112,
            //             descList: [
            //                 {
            //                     descName: 'Wheeled Inspection Robot for Power Industry	',
            //                     sort: 0
            //                 },
            //                 {
            //                     descName: 'Specification',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Dimension (L×W×H)',
            //                     sort: 2
            //                 },
            //                 {
            //                     descName: 'Protection Level',
            //                     sort: 3
            //                 },
            //                 {
            //                     descName: 'Gradeability',
            //                     sort: 4
            //                 },
            //                 {
            //                     descName: 'Wading Depth',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Main Navigation Mode',
            //                     sort: 6
            //                 },
            //                 {
            //                     descName: 'Ambient Temperature',
            //                     sort: 7
            //                 },
            //                 {
            //                     descName: 'Curb Weight',
            //                     sort: 8
            //                 },
            //                 {
            //                     descName: 'Walking Speed',
            //                     sort: 9
            //                 }, {
            //                     descName: 'Obstacle Surmounting Ability',
            //                     sort: 10
            //                 },
            //                 {
            //                     descName: 'Battery Endurance',
            //                     sort: 11
            //                 },
            //                 {
            //                     descName: 'Repetitive Navigation Positioning Accuracy',
            //                     sort: 12
            //                 },
            //                 {
            //                     descName: 'Ambient Relative Humidity',
            //                     sort: 13
            //                 }, {
            //                     descName: '≤650mm×550mm×800mm',
            //                     sort: 14
            //                 },
            //                 {
            //                     descName: 'IP55',
            //                     sort: 15
            //                 },
            //                 {
            //                     descName: '≤15°',
            //                     sort: 16
            //                 },
            //                 {
            //                     descName: '80mm',
            //                     sort: 17
            //                 },
            //                 {
            //                     descName: 'Lidar (3D) + Inertial Navigation',
            //                     sort: 18
            //                 },
            //                 {
            //                     descName: '-25°C-+60°C',
            //                     sort: 19
            //                 },
            //                 {
            //                     descName: '≤40kg',
            //                     sort: 20
            //                 },
            //                 {
            //                     descName: '0-0.8m/s,Speed Adjustable',
            //                     sort: 21
            //                 }, {
            //                     descName: '40mm',
            //                     sort: 22
            //                 },
            //                 {
            //                     descName: '≥8h',
            //                     sort: 23
            //                 },
            //                 {
            //                     descName: '≤±2cm',
            //                     sort: 24
            //                 },
            //                 {
            //                     descName: '5%-95%',
            //                     sort: 25
            //                 }],
            //             type: 'image',
            //             sort: 10
            //         },
            //         {
            //             pcElementId: 102,
            //             phoneElementId: 113,
            //             descList: [
            //                 {
            //                     descName: 'Wheeled Inspection Robot for Power Industry',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Efficient operation, improving inspection efficiency and quality',
            //                     sort: 2
            //                 }],
            //             type: 'image',
            //             sort: 11
            //         }
            //     ],
            elmentList:[]
        }
    },
    created() {
        // this.$store.dispatch("pageset/getbannerIndex", 0);
        // this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.elmentList = this.$store.state.pageset.sourceData.elmentList;
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}